import React, { useState } from 'react';
import * as styles from './header.module.scss';
import Logo from "./logo";
import { graphql, Link, useStaticQuery } from "gatsby";

import UserCircleSVG from "../../icons/fontawesome/light/user-circle.svg";
import BarsSVG from "../../icons/fontawesome/light/bars.svg";

import HeaderNav from "./header-nav";
import HeaderMegamenu from "./header-megamenu";

import ShoppingBasketSVG from '../../icons/envato/shoppingBasket.svg';
import SearchSVG from "../../icons/fontawesome/light/search.svg";

import { useMenuContext } from "../../provider/menuProvider";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { useStoreContext } from "../../provider/storeProvider";

const Header = ( { className = '', data } ) => {

    const menuContext = useMenuContext();
    const storeContext = useStoreContext();


    return <>
        { menuContext.currentMegamenu && <div className={styles.headerShadow} /> }
        <div className={className + " shadow-sm " + styles.headerWrapper}>


        <div className={styles.subHeader}>

            <div className="container">
                <div className="row">
                    <div className="d-flex justify-content-between align-items-center">

                        <Link to={"/"}>
                            <Logo className={styles.logo} />
                        </Link>


                        <p className={"d-none d-lg-block"}>{data.strapiPageConfig.teaser}</p>

                        <Link to={"/suche"} className={styles.search + " d-flex align-items-center"}>
                            <SearchSVG className={"me-1 svgFill"} />
                            Suche
                        </Link>

                        {/*<div className={"d-none d-lg-block"}>*/}
                        {/*    <div className="d-flex align-items-center">*/}
                        {/*        <UserCircleSVG className={"me-1 d-block"} />*/}
                        {/*        <p>Mein Konto</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="d-block d-xl-none me-1">

                            { storeContext.cartAmount > 0 && <></>}

                            <Link to={"/warenkorb"} className={"c-pointer p-relative me-3"}>
                                <ShoppingBasketSVG className={styles.mobileWarenkorb}  />
                            </Link>


                            <BarsSVG className={styles.bars}
                                     onClick={
                                        () => menuContext.setShowMobileOverlay(m => true)
                                     } />

                        </div>


                    </div>

                </div>
            </div>

        </div>

        <div className={styles.header + " bg-white d-none d-xl-block"}>
            <div className="container">
                <div className="row">

                    <div className="d-flex align-items-center">

                        <nav className={styles.nav}>
                            <ul>
                                { data.strapiMenu.elements?.map (
                                    n => {
                                        const category = data.allStrapiCategories.nodes.find( c => c.strapiId === n.category.id);

                                        return <>
                                            <HeaderNav    key={n.id}
                                                          onClick={
                                                              () => {
                                                                  menuContext.setCurrentMegamenu(category.id);
                                                              }
                                                          }
                                                          category={category}
                                                          node={n}/>

                                            <HeaderMegamenu key={"M" + n.id}
                                                            category={category}
                                                            onClose={
                                                                () => {
                                                                    menuContext.setCurrentMegamenu(null);
                                                                    clearAllBodyScrollLocks();
                                                                }
                                                            }
                                                            onSelection={
                                                                () => {
                                                                    menuContext.setCurrentMegamenu(null);
                                                                    clearAllBodyScrollLocks();
                                                                }
                                                            }
                                            />
                                        </>;

                                    }
                                )}
                            </ul>
                        </nav>

                        <Link to={"/warenkorb"} className={"ms-auto c-pointer p-relative"}>
                            <ShoppingBasketSVG className={styles.shoppingBag}  />

                            <p className={styles.cartAmount}>
                                { storeContext.cartAmount }
                            </p>

                        </Link>


                    </div>

                </div>
            </div>
        </div>

    </div>;
    </>;
};

export default Header;
