import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Logo = ( { className = '' } ) => {

    const data = useStaticQuery(
        graphql`
        query logoImg {
          file(name: {eq: "logo-transparent"}) {
              name
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
          }
        }`
    );

    return <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} className={className} />;
};

export default Logo;

