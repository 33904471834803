// extracted by mini-css-extract-plugin
export const alignBaseline = "footer-module--align-baseline--17imB";
export const alignTop = "footer-module--align-top--3V1C2";
export const alignMiddle = "footer-module--align-middle--2ykhv";
export const alignBottom = "footer-module--align-bottom--zqTKR";
export const alignTextBottom = "footer-module--align-text-bottom--2cK9Z";
export const alignTextTop = "footer-module--align-text-top--3n0Oc";
export const floatStart = "footer-module--float-start--2mSoF";
export const floatEnd = "footer-module--float-end--2BWLg";
export const floatNone = "footer-module--float-none--1cfUR";
export const overflowAuto = "footer-module--overflow-auto--110fu";
export const overflowHidden = "footer-module--overflow-hidden--3_V9k";
export const overflowVisible = "footer-module--overflow-visible--KNzUA";
export const overflowScroll = "footer-module--overflow-scroll--37DxR";
export const dInline = "footer-module--d-inline--JQ3y1";
export const dInlineBlock = "footer-module--d-inline-block--2xWWM";
export const dBlock = "footer-module--d-block--3U52N";
export const dGrid = "footer-module--d-grid--2TDRP";
export const dTable = "footer-module--d-table--1zOHZ";
export const dTableRow = "footer-module--d-table-row--yicO6";
export const dTableCell = "footer-module--d-table-cell--2hmhA";
export const dFlex = "footer-module--d-flex--2bPKO";
export const dInlineFlex = "footer-module--d-inline-flex--1aRP7";
export const dNone = "footer-module--d-none--2X6NM";
export const shadow = "footer-module--shadow--fjVCE";
export const shadowSm = "footer-module--shadow-sm--25qwB";
export const shadowLg = "footer-module--shadow-lg--2EjAm";
export const shadowNone = "footer-module--shadow-none--2oOee";
export const positionStatic = "footer-module--position-static--3veNe";
export const positionRelative = "footer-module--position-relative--3PLIw";
export const positionAbsolute = "footer-module--position-absolute--cOhRa";
export const positionFixed = "footer-module--position-fixed--nLM14";
export const positionSticky = "footer-module--position-sticky--2OX-a";
export const top0 = "footer-module--top-0--3vfLU";
export const top50 = "footer-module--top-50--lbko-";
export const top100 = "footer-module--top-100--2CbJH";
export const bottom0 = "footer-module--bottom-0--qGgTq";
export const bottom50 = "footer-module--bottom-50--35BWP";
export const bottom100 = "footer-module--bottom-100--378zm";
export const start0 = "footer-module--start-0--3Qu7n";
export const start50 = "footer-module--start-50--3jLEP";
export const start100 = "footer-module--start-100--288Eh";
export const end0 = "footer-module--end-0--3DDF0";
export const end50 = "footer-module--end-50--11C91";
export const end100 = "footer-module--end-100--OTR5q";
export const translateMiddle = "footer-module--translate-middle--3ZvFF";
export const translateMiddleX = "footer-module--translate-middle-x--1gLih";
export const translateMiddleY = "footer-module--translate-middle-y--1RsT2";
export const border = "footer-module--border--2eGIA";
export const border0 = "footer-module--border-0--2Txyf";
export const borderTop = "footer-module--border-top--1KWF3";
export const borderTop0 = "footer-module--border-top-0--1zH3c";
export const borderEnd = "footer-module--border-end--Ob3Ra";
export const borderEnd0 = "footer-module--border-end-0--2YP8W";
export const borderBottom = "footer-module--border-bottom--INjtE";
export const borderBottom0 = "footer-module--border-bottom-0--X60gx";
export const borderStart = "footer-module--border-start--2-KiR";
export const borderStart0 = "footer-module--border-start-0--T8o0Q";
export const borderPrimary = "footer-module--border-primary--2gtze";
export const borderSecondary = "footer-module--border-secondary--1npdv";
export const borderSuccess = "footer-module--border-success--2bG5B";
export const borderInfo = "footer-module--border-info--3a81z";
export const borderWarning = "footer-module--border-warning--34ELZ";
export const borderDanger = "footer-module--border-danger--3uAdc";
export const borderLight = "footer-module--border-light--2AL9K";
export const borderDark = "footer-module--border-dark--15NxW";
export const borderWhite = "footer-module--border-white--1R6Ut";
export const border1 = "footer-module--border-1--2Z1CB";
export const border2 = "footer-module--border-2--3iCgr";
export const border3 = "footer-module--border-3--12mAn";
export const border4 = "footer-module--border-4--2wu47";
export const border5 = "footer-module--border-5--18BHn";
export const w25 = "footer-module--w-25--1BiFk";
export const w50 = "footer-module--w-50--1MXL9";
export const w75 = "footer-module--w-75--1FLz8";
export const w100 = "footer-module--w-100--b6vph";
export const wAuto = "footer-module--w-auto--RU6j7";
export const mw100 = "footer-module--mw-100--5tRhq";
export const vw100 = "footer-module--vw-100--2eL3P";
export const minVw100 = "footer-module--min-vw-100--2VIND";
export const h25 = "footer-module--h-25--S16eN";
export const h50 = "footer-module--h-50--3Cj0w";
export const h75 = "footer-module--h-75--1vyZe";
export const h100 = "footer-module--h-100--35pzX";
export const hAuto = "footer-module--h-auto--aYymb";
export const mh100 = "footer-module--mh-100--QJuiv";
export const vh100 = "footer-module--vh-100--AVZq1";
export const minVh100 = "footer-module--min-vh-100--w7MSx";
export const flexFill = "footer-module--flex-fill--1eECs";
export const flexRow = "footer-module--flex-row--2xw6V";
export const flexColumn = "footer-module--flex-column--1DPAf";
export const flexRowReverse = "footer-module--flex-row-reverse--lFIvh";
export const flexColumnReverse = "footer-module--flex-column-reverse--1dY1t";
export const flexGrow0 = "footer-module--flex-grow-0--3zkSS";
export const flexGrow1 = "footer-module--flex-grow-1--2xdUg";
export const flexShrink0 = "footer-module--flex-shrink-0--1EECS";
export const flexShrink1 = "footer-module--flex-shrink-1--3BKOT";
export const flexWrap = "footer-module--flex-wrap--2P8QJ";
export const flexNowrap = "footer-module--flex-nowrap--3Nz1r";
export const flexWrapReverse = "footer-module--flex-wrap-reverse--3O0C1";
export const gap0 = "footer-module--gap-0--sKhaH";
export const gap1 = "footer-module--gap-1--13LZj";
export const gap2 = "footer-module--gap-2--6gI6e";
export const gap3 = "footer-module--gap-3--14Z2a";
export const gap4 = "footer-module--gap-4--19YSt";
export const gap5 = "footer-module--gap-5--28S99";
export const justifyContentStart = "footer-module--justify-content-start--1oy3i";
export const justifyContentEnd = "footer-module--justify-content-end--2gydk";
export const justifyContentCenter = "footer-module--justify-content-center--MsOJT";
export const justifyContentBetween = "footer-module--justify-content-between--2ukRu";
export const justifyContentAround = "footer-module--justify-content-around--3HglB";
export const justifyContentEvenly = "footer-module--justify-content-evenly--X_CIN";
export const alignItemsStart = "footer-module--align-items-start--3_OKK";
export const alignItemsEnd = "footer-module--align-items-end--3QdR-";
export const alignItemsCenter = "footer-module--align-items-center--29BQx";
export const alignItemsBaseline = "footer-module--align-items-baseline--2ETUN";
export const alignItemsStretch = "footer-module--align-items-stretch--SbOWT";
export const alignContentStart = "footer-module--align-content-start--1Lpok";
export const alignContentEnd = "footer-module--align-content-end--K3laH";
export const alignContentCenter = "footer-module--align-content-center--30LHc";
export const alignContentBetween = "footer-module--align-content-between--2cfPK";
export const alignContentAround = "footer-module--align-content-around--28GiK";
export const alignContentStretch = "footer-module--align-content-stretch--1DypB";
export const alignSelfAuto = "footer-module--align-self-auto--3RZMS";
export const alignSelfStart = "footer-module--align-self-start--2xd3p";
export const alignSelfEnd = "footer-module--align-self-end--2YbfP";
export const alignSelfCenter = "footer-module--align-self-center--3d_iR";
export const alignSelfBaseline = "footer-module--align-self-baseline--19Dlu";
export const alignSelfStretch = "footer-module--align-self-stretch--ccXaz";
export const orderFirst = "footer-module--order-first--mJQQR";
export const order0 = "footer-module--order-0--2VaXm";
export const order1 = "footer-module--order-1--2Xk31";
export const order2 = "footer-module--order-2--16Ncb";
export const order3 = "footer-module--order-3--6fAX_";
export const order4 = "footer-module--order-4--3m1mu";
export const order5 = "footer-module--order-5--3OyZL";
export const orderLast = "footer-module--order-last--DiOrZ";
export const m0 = "footer-module--m-0--2ZQc9";
export const m1 = "footer-module--m-1--1bskP";
export const m2 = "footer-module--m-2--2inbD";
export const m3 = "footer-module--m-3--1OfZB";
export const m4 = "footer-module--m-4--2hukm";
export const m5 = "footer-module--m-5--3Cznm";
export const mAuto = "footer-module--m-auto--2cF8p";
export const mx0 = "footer-module--mx-0--2gZUb";
export const mx1 = "footer-module--mx-1--2yFSL";
export const mx2 = "footer-module--mx-2--Vecmd";
export const mx3 = "footer-module--mx-3--6zqGK";
export const mx4 = "footer-module--mx-4--3K34t";
export const mx5 = "footer-module--mx-5--sq_6L";
export const mxAuto = "footer-module--mx-auto--1Uq4o";
export const my0 = "footer-module--my-0--27h-o";
export const my1 = "footer-module--my-1--2P_vv";
export const my2 = "footer-module--my-2--3DkSV";
export const my3 = "footer-module--my-3--2tJfH";
export const my4 = "footer-module--my-4--1WyHk";
export const my5 = "footer-module--my-5--1R-7a";
export const myAuto = "footer-module--my-auto--1ULLn";
export const mt0 = "footer-module--mt-0--2izL4";
export const mt1 = "footer-module--mt-1--dZNPD";
export const mt2 = "footer-module--mt-2--1YNKP";
export const mt3 = "footer-module--mt-3--1onkz";
export const mt4 = "footer-module--mt-4--2vm2O";
export const mt5 = "footer-module--mt-5--LFe3S";
export const mtAuto = "footer-module--mt-auto--1ucRQ";
export const me0 = "footer-module--me-0--21hIL";
export const me1 = "footer-module--me-1--3FsAd";
export const me2 = "footer-module--me-2--3QgSL";
export const me3 = "footer-module--me-3--11RL3";
export const me4 = "footer-module--me-4--2zUkP";
export const me5 = "footer-module--me-5--VzTQS";
export const meAuto = "footer-module--me-auto--27hL6";
export const mb0 = "footer-module--mb-0--1f4NE";
export const mb1 = "footer-module--mb-1--2ohcI";
export const mb2 = "footer-module--mb-2--YQdS0";
export const mb3 = "footer-module--mb-3--2Nu17";
export const mb4 = "footer-module--mb-4--a9rHx";
export const mb5 = "footer-module--mb-5--2ONPr";
export const mbAuto = "footer-module--mb-auto--1Nmxq";
export const ms0 = "footer-module--ms-0--3aINC";
export const ms1 = "footer-module--ms-1--3Sei0";
export const ms2 = "footer-module--ms-2--38dU-";
export const ms3 = "footer-module--ms-3--2FHkG";
export const ms4 = "footer-module--ms-4--2qJyX";
export const ms5 = "footer-module--ms-5--203wK";
export const msAuto = "footer-module--ms-auto--1DSZc";
export const p0 = "footer-module--p-0--3fROh";
export const p1 = "footer-module--p-1--3Ixj4";
export const p2 = "footer-module--p-2--3jLMx";
export const p3 = "footer-module--p-3--1r7Qd";
export const p4 = "footer-module--p-4--1TiBy";
export const p5 = "footer-module--p-5--3YoHs";
export const px0 = "footer-module--px-0--3OsDz";
export const px1 = "footer-module--px-1--2I-Tp";
export const px2 = "footer-module--px-2--2_plo";
export const px3 = "footer-module--px-3--13F_S";
export const px4 = "footer-module--px-4--2STzq";
export const px5 = "footer-module--px-5--Q-wj8";
export const py0 = "footer-module--py-0--1sgNd";
export const py1 = "footer-module--py-1--2sxB3";
export const py2 = "footer-module--py-2--XRFKt";
export const py3 = "footer-module--py-3--mMRmO";
export const py4 = "footer-module--py-4--3UGzC";
export const py5 = "footer-module--py-5--2MfZr";
export const pt0 = "footer-module--pt-0--2k4e7";
export const pt1 = "footer-module--pt-1--wj17E";
export const pt2 = "footer-module--pt-2--3HvNy";
export const pt3 = "footer-module--pt-3--opdUF";
export const pt4 = "footer-module--pt-4--2Abju";
export const pt5 = "footer-module--pt-5--3NrJy";
export const pe0 = "footer-module--pe-0--3Y-jo";
export const pe1 = "footer-module--pe-1--uHAB4";
export const pe2 = "footer-module--pe-2--2gWrt";
export const pe3 = "footer-module--pe-3--2pBzm";
export const pe4 = "footer-module--pe-4--2GnXX";
export const pe5 = "footer-module--pe-5--3XTeU";
export const pb0 = "footer-module--pb-0--8H7-P";
export const pb1 = "footer-module--pb-1--2SqOT";
export const pb2 = "footer-module--pb-2--1tj-h";
export const pb3 = "footer-module--pb-3--3rb0p";
export const pb4 = "footer-module--pb-4--EBiHg";
export const pb5 = "footer-module--pb-5--2Z7jK";
export const ps0 = "footer-module--ps-0--3Dy2m";
export const ps1 = "footer-module--ps-1--BT_jU";
export const ps2 = "footer-module--ps-2--3e5zG";
export const ps3 = "footer-module--ps-3--2amS9";
export const ps4 = "footer-module--ps-4--Ys-Pg";
export const ps5 = "footer-module--ps-5--NgvjZ";
export const fs1 = "footer-module--fs-1--2A3YO";
export const fs2 = "footer-module--fs-2--48HWM";
export const fs3 = "footer-module--fs-3--QBiq2";
export const fs4 = "footer-module--fs-4--2Fbk2";
export const fs5 = "footer-module--fs-5--1c5CF";
export const fs6 = "footer-module--fs-6--1ggtJ";
export const fstItalic = "footer-module--fst-italic--vf0EA";
export const fstNormal = "footer-module--fst-normal--7c_uR";
export const fwLight = "footer-module--fw-light--qjzRj";
export const fwLighter = "footer-module--fw-lighter--2aPNr";
export const fwNormal = "footer-module--fw-normal--3hyIt";
export const fwBold = "footer-module--fw-bold--14yHX";
export const fwBolder = "footer-module--fw-bolder--3lyTR";
export const textLowercase = "footer-module--text-lowercase--1MIXU";
export const textUppercase = "footer-module--text-uppercase--3jMIr";
export const textCapitalize = "footer-module--text-capitalize--mM0Wx";
export const textStart = "footer-module--text-start--DgPNH";
export const textEnd = "footer-module--text-end--2IObk";
export const textCenter = "footer-module--text-center--2jcpU";
export const textPrimary = "footer-module--text-primary--37jEi";
export const textSecondary = "footer-module--text-secondary--23BHh";
export const textSuccess = "footer-module--text-success--3tuI7";
export const textInfo = "footer-module--text-info--Sn7Fm";
export const textWarning = "footer-module--text-warning--3RvUD";
export const textDanger = "footer-module--text-danger--3Fwzs";
export const textLight = "footer-module--text-light--1qedS";
export const textDark = "footer-module--text-dark--3qf1f";
export const textWhite = "footer-module--text-white--3mmdD";
export const textBody = "footer-module--text-body--CfAdu";
export const textMuted = "footer-module--text-muted--1sZLz";
export const textBlack50 = "footer-module--text-black-50--3EM-p";
export const textWhite50 = "footer-module--text-white-50--2V-5i";
export const textReset = "footer-module--text-reset--3fR1E";
export const lh1 = "footer-module--lh-1--2nKjC";
export const lhSm = "footer-module--lh-sm--3UhQp";
export const lhBase = "footer-module--lh-base--1JqUW";
export const lhLg = "footer-module--lh-lg--1DE41";
export const bgPrimary = "footer-module--bg-primary--3aDkt";
export const bgSecondary = "footer-module--bg-secondary--2_jRD";
export const bgSuccess = "footer-module--bg-success--2ziGq";
export const bgInfo = "footer-module--bg-info--1xLLe";
export const bgWarning = "footer-module--bg-warning--38e0x";
export const bgDanger = "footer-module--bg-danger--120Nh";
export const bgLight = "footer-module--bg-light--3Exy_";
export const bgDark = "footer-module--bg-dark--1o_6C";
export const bgBody = "footer-module--bg-body--3HGyK";
export const bgWhite = "footer-module--bg-white--1H-RW";
export const bgTransparent = "footer-module--bg-transparent--1k_QB";
export const bgGradient = "footer-module--bg-gradient--1Ka3H";
export const textWrap = "footer-module--text-wrap--2WTQs";
export const textNowrap = "footer-module--text-nowrap--2y9xg";
export const textDecorationNone = "footer-module--text-decoration-none--Y0Jhu";
export const textDecorationUnderline = "footer-module--text-decoration-underline--hU6Ii";
export const textDecorationLineThrough = "footer-module--text-decoration-line-through--1R_Pp";
export const textBreak = "footer-module--text-break--bopu8";
export const fontMonospace = "footer-module--font-monospace--33Igq";
export const userSelectAll = "footer-module--user-select-all--2vofV";
export const userSelectAuto = "footer-module--user-select-auto--1wN6N";
export const userSelectNone = "footer-module--user-select-none--1jW-3";
export const peNone = "footer-module--pe-none--QEze0";
export const peAuto = "footer-module--pe-auto--2axPE";
export const rounded = "footer-module--rounded--344d2";
export const rounded0 = "footer-module--rounded-0--1n-n9";
export const rounded1 = "footer-module--rounded-1--3lNJ-";
export const rounded2 = "footer-module--rounded-2--2jPGG";
export const rounded3 = "footer-module--rounded-3--2ZnTP";
export const roundedCircle = "footer-module--rounded-circle--3azqZ";
export const roundedPill = "footer-module--rounded-pill--3ZfdQ";
export const roundedTop = "footer-module--rounded-top--kT14g";
export const roundedEnd = "footer-module--rounded-end--AvkU1";
export const roundedBottom = "footer-module--rounded-bottom--tZXzo";
export const roundedStart = "footer-module--rounded-start--2kCKt";
export const visible = "footer-module--visible--1ryyf";
export const invisible = "footer-module--invisible--1HXmo";
export const floatSmStart = "footer-module--float-sm-start--3qjv5";
export const floatSmEnd = "footer-module--float-sm-end--teVCh";
export const floatSmNone = "footer-module--float-sm-none--1SK0R";
export const dSmInline = "footer-module--d-sm-inline--1oiHX";
export const dSmInlineBlock = "footer-module--d-sm-inline-block--1ionQ";
export const dSmBlock = "footer-module--d-sm-block--2jfEI";
export const dSmGrid = "footer-module--d-sm-grid--3O3uf";
export const dSmTable = "footer-module--d-sm-table--2GCOb";
export const dSmTableRow = "footer-module--d-sm-table-row--2A3sk";
export const dSmTableCell = "footer-module--d-sm-table-cell--2NyCg";
export const dSmFlex = "footer-module--d-sm-flex--2kk8U";
export const dSmInlineFlex = "footer-module--d-sm-inline-flex--GVJrS";
export const dSmNone = "footer-module--d-sm-none--2TWKt";
export const flexSmFill = "footer-module--flex-sm-fill--158Ik";
export const flexSmRow = "footer-module--flex-sm-row--3nMNQ";
export const flexSmColumn = "footer-module--flex-sm-column--Fe1yS";
export const flexSmRowReverse = "footer-module--flex-sm-row-reverse--16dka";
export const flexSmColumnReverse = "footer-module--flex-sm-column-reverse--zLdDC";
export const flexSmGrow0 = "footer-module--flex-sm-grow-0--2o6cI";
export const flexSmGrow1 = "footer-module--flex-sm-grow-1--10_Xo";
export const flexSmShrink0 = "footer-module--flex-sm-shrink-0--1vySB";
export const flexSmShrink1 = "footer-module--flex-sm-shrink-1--1-irQ";
export const flexSmWrap = "footer-module--flex-sm-wrap--3ybKV";
export const flexSmNowrap = "footer-module--flex-sm-nowrap--2TQVh";
export const flexSmWrapReverse = "footer-module--flex-sm-wrap-reverse--3xhGF";
export const gapSm0 = "footer-module--gap-sm-0--QXODc";
export const gapSm1 = "footer-module--gap-sm-1--ZN0TO";
export const gapSm2 = "footer-module--gap-sm-2--XHUM_";
export const gapSm3 = "footer-module--gap-sm-3--UcBx3";
export const gapSm4 = "footer-module--gap-sm-4--2St_j";
export const gapSm5 = "footer-module--gap-sm-5--1LV4M";
export const justifyContentSmStart = "footer-module--justify-content-sm-start--mSQH7";
export const justifyContentSmEnd = "footer-module--justify-content-sm-end--31fa2";
export const justifyContentSmCenter = "footer-module--justify-content-sm-center--56JVM";
export const justifyContentSmBetween = "footer-module--justify-content-sm-between--2KFos";
export const justifyContentSmAround = "footer-module--justify-content-sm-around--bqlyc";
export const justifyContentSmEvenly = "footer-module--justify-content-sm-evenly--1EBva";
export const alignItemsSmStart = "footer-module--align-items-sm-start---hkmB";
export const alignItemsSmEnd = "footer-module--align-items-sm-end--2ZpQA";
export const alignItemsSmCenter = "footer-module--align-items-sm-center--2al5X";
export const alignItemsSmBaseline = "footer-module--align-items-sm-baseline--2HoHW";
export const alignItemsSmStretch = "footer-module--align-items-sm-stretch--3Eb7a";
export const alignContentSmStart = "footer-module--align-content-sm-start--1MfAK";
export const alignContentSmEnd = "footer-module--align-content-sm-end--1ZUWb";
export const alignContentSmCenter = "footer-module--align-content-sm-center--2xMOA";
export const alignContentSmBetween = "footer-module--align-content-sm-between--2gsvg";
export const alignContentSmAround = "footer-module--align-content-sm-around--3rjNs";
export const alignContentSmStretch = "footer-module--align-content-sm-stretch--2F5oj";
export const alignSelfSmAuto = "footer-module--align-self-sm-auto--v7Zjf";
export const alignSelfSmStart = "footer-module--align-self-sm-start--1qFQg";
export const alignSelfSmEnd = "footer-module--align-self-sm-end--3e0T9";
export const alignSelfSmCenter = "footer-module--align-self-sm-center--2XrZu";
export const alignSelfSmBaseline = "footer-module--align-self-sm-baseline--2k-cQ";
export const alignSelfSmStretch = "footer-module--align-self-sm-stretch--1NFRk";
export const orderSmFirst = "footer-module--order-sm-first--2ri7y";
export const orderSm0 = "footer-module--order-sm-0--1vxW7";
export const orderSm1 = "footer-module--order-sm-1--mf7e6";
export const orderSm2 = "footer-module--order-sm-2--Kf1X7";
export const orderSm3 = "footer-module--order-sm-3--1MqhO";
export const orderSm4 = "footer-module--order-sm-4--3dRYW";
export const orderSm5 = "footer-module--order-sm-5--MGowe";
export const orderSmLast = "footer-module--order-sm-last--2y1qz";
export const mSm0 = "footer-module--m-sm-0--3WTea";
export const mSm1 = "footer-module--m-sm-1--1b15J";
export const mSm2 = "footer-module--m-sm-2--2qc-M";
export const mSm3 = "footer-module--m-sm-3--2UViK";
export const mSm4 = "footer-module--m-sm-4--IKiZ-";
export const mSm5 = "footer-module--m-sm-5--27JkQ";
export const mSmAuto = "footer-module--m-sm-auto--2Py66";
export const mxSm0 = "footer-module--mx-sm-0--2sfQv";
export const mxSm1 = "footer-module--mx-sm-1--2NEAJ";
export const mxSm2 = "footer-module--mx-sm-2--uHvvB";
export const mxSm3 = "footer-module--mx-sm-3--gUQUI";
export const mxSm4 = "footer-module--mx-sm-4--2oR3Q";
export const mxSm5 = "footer-module--mx-sm-5--2xluR";
export const mxSmAuto = "footer-module--mx-sm-auto--4hOZ2";
export const mySm0 = "footer-module--my-sm-0--2pAcn";
export const mySm1 = "footer-module--my-sm-1--3K3Kw";
export const mySm2 = "footer-module--my-sm-2--3DV1H";
export const mySm3 = "footer-module--my-sm-3--3FcXG";
export const mySm4 = "footer-module--my-sm-4--1E8Sj";
export const mySm5 = "footer-module--my-sm-5--3asnx";
export const mySmAuto = "footer-module--my-sm-auto--2n8ib";
export const mtSm0 = "footer-module--mt-sm-0--lqueM";
export const mtSm1 = "footer-module--mt-sm-1--3FI4R";
export const mtSm2 = "footer-module--mt-sm-2--25ivs";
export const mtSm3 = "footer-module--mt-sm-3--1OK8f";
export const mtSm4 = "footer-module--mt-sm-4--wLB4U";
export const mtSm5 = "footer-module--mt-sm-5--1QRHT";
export const mtSmAuto = "footer-module--mt-sm-auto--3NUe1";
export const meSm0 = "footer-module--me-sm-0--339Gi";
export const meSm1 = "footer-module--me-sm-1--3R2pb";
export const meSm2 = "footer-module--me-sm-2--2x2Ix";
export const meSm3 = "footer-module--me-sm-3--2YyJG";
export const meSm4 = "footer-module--me-sm-4--1A4jA";
export const meSm5 = "footer-module--me-sm-5--WIq_U";
export const meSmAuto = "footer-module--me-sm-auto--iRQcp";
export const mbSm0 = "footer-module--mb-sm-0--2UpRk";
export const mbSm1 = "footer-module--mb-sm-1--KpYAA";
export const mbSm2 = "footer-module--mb-sm-2--2YrRQ";
export const mbSm3 = "footer-module--mb-sm-3--3dDt9";
export const mbSm4 = "footer-module--mb-sm-4--3bJf6";
export const mbSm5 = "footer-module--mb-sm-5--JmW-I";
export const mbSmAuto = "footer-module--mb-sm-auto--22aCE";
export const msSm0 = "footer-module--ms-sm-0--1iSMg";
export const msSm1 = "footer-module--ms-sm-1--3Q4mZ";
export const msSm2 = "footer-module--ms-sm-2--1pP3d";
export const msSm3 = "footer-module--ms-sm-3--13OYW";
export const msSm4 = "footer-module--ms-sm-4--14KXb";
export const msSm5 = "footer-module--ms-sm-5--3ZXIx";
export const msSmAuto = "footer-module--ms-sm-auto--3BUvg";
export const pSm0 = "footer-module--p-sm-0--2Jok3";
export const pSm1 = "footer-module--p-sm-1--1oP7i";
export const pSm2 = "footer-module--p-sm-2--2BNUB";
export const pSm3 = "footer-module--p-sm-3--2SYpb";
export const pSm4 = "footer-module--p-sm-4--1C5sI";
export const pSm5 = "footer-module--p-sm-5--14nAh";
export const pxSm0 = "footer-module--px-sm-0--W8ROQ";
export const pxSm1 = "footer-module--px-sm-1--3krCz";
export const pxSm2 = "footer-module--px-sm-2--3K0by";
export const pxSm3 = "footer-module--px-sm-3--1IFJk";
export const pxSm4 = "footer-module--px-sm-4--3KM5T";
export const pxSm5 = "footer-module--px-sm-5--3Hmcc";
export const pySm0 = "footer-module--py-sm-0--349Qz";
export const pySm1 = "footer-module--py-sm-1--2gu4-";
export const pySm2 = "footer-module--py-sm-2--2WSwR";
export const pySm3 = "footer-module--py-sm-3--hzpi5";
export const pySm4 = "footer-module--py-sm-4--Cc2qI";
export const pySm5 = "footer-module--py-sm-5--3FXx6";
export const ptSm0 = "footer-module--pt-sm-0--1h4Nm";
export const ptSm1 = "footer-module--pt-sm-1--XR4ew";
export const ptSm2 = "footer-module--pt-sm-2--B12-S";
export const ptSm3 = "footer-module--pt-sm-3--upRcd";
export const ptSm4 = "footer-module--pt-sm-4--1qenb";
export const ptSm5 = "footer-module--pt-sm-5--2pdXF";
export const peSm0 = "footer-module--pe-sm-0--2m0EM";
export const peSm1 = "footer-module--pe-sm-1--3Srxr";
export const peSm2 = "footer-module--pe-sm-2--3iDRW";
export const peSm3 = "footer-module--pe-sm-3--217M3";
export const peSm4 = "footer-module--pe-sm-4--jVOMi";
export const peSm5 = "footer-module--pe-sm-5--2g5AC";
export const pbSm0 = "footer-module--pb-sm-0--tHlGE";
export const pbSm1 = "footer-module--pb-sm-1--37HZx";
export const pbSm2 = "footer-module--pb-sm-2--Ftx5j";
export const pbSm3 = "footer-module--pb-sm-3--1avId";
export const pbSm4 = "footer-module--pb-sm-4--3ZD-v";
export const pbSm5 = "footer-module--pb-sm-5--3gAq2";
export const psSm0 = "footer-module--ps-sm-0--2b9Pu";
export const psSm1 = "footer-module--ps-sm-1--2Nzle";
export const psSm2 = "footer-module--ps-sm-2--1yrxM";
export const psSm3 = "footer-module--ps-sm-3--3-sxr";
export const psSm4 = "footer-module--ps-sm-4--1Z5wq";
export const psSm5 = "footer-module--ps-sm-5--1QRiK";
export const textSmStart = "footer-module--text-sm-start--2RWyB";
export const textSmEnd = "footer-module--text-sm-end--nOocU";
export const textSmCenter = "footer-module--text-sm-center--1vkIm";
export const floatMdStart = "footer-module--float-md-start--2ZzKC";
export const floatMdEnd = "footer-module--float-md-end--2SHpQ";
export const floatMdNone = "footer-module--float-md-none--1f1UP";
export const dMdInline = "footer-module--d-md-inline--1gW_V";
export const dMdInlineBlock = "footer-module--d-md-inline-block--3Sv1o";
export const dMdBlock = "footer-module--d-md-block--Y5utK";
export const dMdGrid = "footer-module--d-md-grid--27z6I";
export const dMdTable = "footer-module--d-md-table--1axdY";
export const dMdTableRow = "footer-module--d-md-table-row--3ILZT";
export const dMdTableCell = "footer-module--d-md-table-cell--2MUtF";
export const dMdFlex = "footer-module--d-md-flex--1rK_p";
export const dMdInlineFlex = "footer-module--d-md-inline-flex--3OsHb";
export const dMdNone = "footer-module--d-md-none--rvI8Z";
export const flexMdFill = "footer-module--flex-md-fill--2yxqa";
export const flexMdRow = "footer-module--flex-md-row--hM8A4";
export const flexMdColumn = "footer-module--flex-md-column--2kyyg";
export const flexMdRowReverse = "footer-module--flex-md-row-reverse--2oSK5";
export const flexMdColumnReverse = "footer-module--flex-md-column-reverse--2TCKg";
export const flexMdGrow0 = "footer-module--flex-md-grow-0--3RMAp";
export const flexMdGrow1 = "footer-module--flex-md-grow-1--1pLU1";
export const flexMdShrink0 = "footer-module--flex-md-shrink-0--1eP1W";
export const flexMdShrink1 = "footer-module--flex-md-shrink-1--JqTMn";
export const flexMdWrap = "footer-module--flex-md-wrap--1LuPT";
export const flexMdNowrap = "footer-module--flex-md-nowrap--3gf_l";
export const flexMdWrapReverse = "footer-module--flex-md-wrap-reverse--3c7Gy";
export const gapMd0 = "footer-module--gap-md-0--2s-JB";
export const gapMd1 = "footer-module--gap-md-1--3HRv6";
export const gapMd2 = "footer-module--gap-md-2--3i4vw";
export const gapMd3 = "footer-module--gap-md-3--1gZml";
export const gapMd4 = "footer-module--gap-md-4--2n6A8";
export const gapMd5 = "footer-module--gap-md-5--232Yl";
export const justifyContentMdStart = "footer-module--justify-content-md-start--NPd1x";
export const justifyContentMdEnd = "footer-module--justify-content-md-end--3v6sK";
export const justifyContentMdCenter = "footer-module--justify-content-md-center--mru4b";
export const justifyContentMdBetween = "footer-module--justify-content-md-between--z7f4V";
export const justifyContentMdAround = "footer-module--justify-content-md-around--1VQUM";
export const justifyContentMdEvenly = "footer-module--justify-content-md-evenly--3GKZw";
export const alignItemsMdStart = "footer-module--align-items-md-start--avM_u";
export const alignItemsMdEnd = "footer-module--align-items-md-end--14jUL";
export const alignItemsMdCenter = "footer-module--align-items-md-center--lN1So";
export const alignItemsMdBaseline = "footer-module--align-items-md-baseline--1swSo";
export const alignItemsMdStretch = "footer-module--align-items-md-stretch--2XDrK";
export const alignContentMdStart = "footer-module--align-content-md-start--2aH-V";
export const alignContentMdEnd = "footer-module--align-content-md-end--2uJtT";
export const alignContentMdCenter = "footer-module--align-content-md-center--2L9je";
export const alignContentMdBetween = "footer-module--align-content-md-between--3d7sa";
export const alignContentMdAround = "footer-module--align-content-md-around--3jGMo";
export const alignContentMdStretch = "footer-module--align-content-md-stretch--14dts";
export const alignSelfMdAuto = "footer-module--align-self-md-auto--1pRWD";
export const alignSelfMdStart = "footer-module--align-self-md-start--K8SUf";
export const alignSelfMdEnd = "footer-module--align-self-md-end--2kxOG";
export const alignSelfMdCenter = "footer-module--align-self-md-center--3XaIU";
export const alignSelfMdBaseline = "footer-module--align-self-md-baseline--1Ut8Z";
export const alignSelfMdStretch = "footer-module--align-self-md-stretch--3yCG9";
export const orderMdFirst = "footer-module--order-md-first--3TKPK";
export const orderMd0 = "footer-module--order-md-0--8z8wy";
export const orderMd1 = "footer-module--order-md-1--2fVl-";
export const orderMd2 = "footer-module--order-md-2--17Mgv";
export const orderMd3 = "footer-module--order-md-3--1uPsj";
export const orderMd4 = "footer-module--order-md-4--8tM7h";
export const orderMd5 = "footer-module--order-md-5--3bOY3";
export const orderMdLast = "footer-module--order-md-last--6iLVd";
export const mMd0 = "footer-module--m-md-0--2BB0K";
export const mMd1 = "footer-module--m-md-1--rE3Fu";
export const mMd2 = "footer-module--m-md-2--1Q13c";
export const mMd3 = "footer-module--m-md-3--1kGnF";
export const mMd4 = "footer-module--m-md-4--2BfPa";
export const mMd5 = "footer-module--m-md-5--1URti";
export const mMdAuto = "footer-module--m-md-auto--33Y3M";
export const mxMd0 = "footer-module--mx-md-0--1K7U7";
export const mxMd1 = "footer-module--mx-md-1--390nD";
export const mxMd2 = "footer-module--mx-md-2--2ma98";
export const mxMd3 = "footer-module--mx-md-3--2l3Qw";
export const mxMd4 = "footer-module--mx-md-4--36HYx";
export const mxMd5 = "footer-module--mx-md-5--SepbV";
export const mxMdAuto = "footer-module--mx-md-auto--3YORQ";
export const myMd0 = "footer-module--my-md-0--rR7ye";
export const myMd1 = "footer-module--my-md-1--2tdkl";
export const myMd2 = "footer-module--my-md-2--3hshn";
export const myMd3 = "footer-module--my-md-3--1uyvh";
export const myMd4 = "footer-module--my-md-4--ZLSSf";
export const myMd5 = "footer-module--my-md-5--7M2lv";
export const myMdAuto = "footer-module--my-md-auto--2aP80";
export const mtMd0 = "footer-module--mt-md-0--1R1T0";
export const mtMd1 = "footer-module--mt-md-1--3Rd9s";
export const mtMd2 = "footer-module--mt-md-2--EVFkn";
export const mtMd3 = "footer-module--mt-md-3--1R5P8";
export const mtMd4 = "footer-module--mt-md-4--19Vv2";
export const mtMd5 = "footer-module--mt-md-5--deUtL";
export const mtMdAuto = "footer-module--mt-md-auto--5RU0_";
export const meMd0 = "footer-module--me-md-0--3vbJx";
export const meMd1 = "footer-module--me-md-1--1FEgj";
export const meMd2 = "footer-module--me-md-2--3-8A2";
export const meMd3 = "footer-module--me-md-3--15yEu";
export const meMd4 = "footer-module--me-md-4--1zxIG";
export const meMd5 = "footer-module--me-md-5--vgOQd";
export const meMdAuto = "footer-module--me-md-auto--38_hN";
export const mbMd0 = "footer-module--mb-md-0--3Vl0m";
export const mbMd1 = "footer-module--mb-md-1--1QXxA";
export const mbMd2 = "footer-module--mb-md-2--GxcJq";
export const mbMd3 = "footer-module--mb-md-3--gk-KY";
export const mbMd4 = "footer-module--mb-md-4--2JPKd";
export const mbMd5 = "footer-module--mb-md-5--VoehG";
export const mbMdAuto = "footer-module--mb-md-auto--11z6d";
export const msMd0 = "footer-module--ms-md-0--1a2If";
export const msMd1 = "footer-module--ms-md-1--1iZWO";
export const msMd2 = "footer-module--ms-md-2--3uVDk";
export const msMd3 = "footer-module--ms-md-3--2zEDF";
export const msMd4 = "footer-module--ms-md-4--1xhMJ";
export const msMd5 = "footer-module--ms-md-5--TZf6J";
export const msMdAuto = "footer-module--ms-md-auto--N8pik";
export const pMd0 = "footer-module--p-md-0--1gLFE";
export const pMd1 = "footer-module--p-md-1--34Hyv";
export const pMd2 = "footer-module--p-md-2--2lsqd";
export const pMd3 = "footer-module--p-md-3--wDqPz";
export const pMd4 = "footer-module--p-md-4--19FLs";
export const pMd5 = "footer-module--p-md-5--3ibMb";
export const pxMd0 = "footer-module--px-md-0--3u1DK";
export const pxMd1 = "footer-module--px-md-1--2Ulo5";
export const pxMd2 = "footer-module--px-md-2--3Ud_r";
export const pxMd3 = "footer-module--px-md-3--1KM1o";
export const pxMd4 = "footer-module--px-md-4--3Vevo";
export const pxMd5 = "footer-module--px-md-5--UoOPp";
export const pyMd0 = "footer-module--py-md-0--O992f";
export const pyMd1 = "footer-module--py-md-1--3KjwS";
export const pyMd2 = "footer-module--py-md-2--39ORv";
export const pyMd3 = "footer-module--py-md-3--1RIZr";
export const pyMd4 = "footer-module--py-md-4--3Dzbi";
export const pyMd5 = "footer-module--py-md-5--2TU9t";
export const ptMd0 = "footer-module--pt-md-0--1bUbB";
export const ptMd1 = "footer-module--pt-md-1--3nMaR";
export const ptMd2 = "footer-module--pt-md-2--3XJz_";
export const ptMd3 = "footer-module--pt-md-3--3l5ex";
export const ptMd4 = "footer-module--pt-md-4--2qRk_";
export const ptMd5 = "footer-module--pt-md-5--3c4wY";
export const peMd0 = "footer-module--pe-md-0--34bDP";
export const peMd1 = "footer-module--pe-md-1--1mFJ9";
export const peMd2 = "footer-module--pe-md-2--2NH_D";
export const peMd3 = "footer-module--pe-md-3--3tqGB";
export const peMd4 = "footer-module--pe-md-4--24RBI";
export const peMd5 = "footer-module--pe-md-5--32-FT";
export const pbMd0 = "footer-module--pb-md-0--38T9A";
export const pbMd1 = "footer-module--pb-md-1--GGTY6";
export const pbMd2 = "footer-module--pb-md-2--y5X7b";
export const pbMd3 = "footer-module--pb-md-3--1KQAO";
export const pbMd4 = "footer-module--pb-md-4--2_XzB";
export const pbMd5 = "footer-module--pb-md-5--ZWiY8";
export const psMd0 = "footer-module--ps-md-0--2kEpy";
export const psMd1 = "footer-module--ps-md-1--2x9Se";
export const psMd2 = "footer-module--ps-md-2--1WXzF";
export const psMd3 = "footer-module--ps-md-3--2I-QJ";
export const psMd4 = "footer-module--ps-md-4--1QKyh";
export const psMd5 = "footer-module--ps-md-5--sRcNn";
export const textMdStart = "footer-module--text-md-start--1DYTx";
export const textMdEnd = "footer-module--text-md-end--1UfN2";
export const textMdCenter = "footer-module--text-md-center--21g32";
export const floatLgStart = "footer-module--float-lg-start--3uUWW";
export const floatLgEnd = "footer-module--float-lg-end--3jVVm";
export const floatLgNone = "footer-module--float-lg-none--2WgWc";
export const dLgInline = "footer-module--d-lg-inline--11PXD";
export const dLgInlineBlock = "footer-module--d-lg-inline-block--3ZwXX";
export const dLgBlock = "footer-module--d-lg-block--3we3U";
export const dLgGrid = "footer-module--d-lg-grid--g75RH";
export const dLgTable = "footer-module--d-lg-table--2lKZy";
export const dLgTableRow = "footer-module--d-lg-table-row--3_MeI";
export const dLgTableCell = "footer-module--d-lg-table-cell--3HGdK";
export const dLgFlex = "footer-module--d-lg-flex--1IReV";
export const dLgInlineFlex = "footer-module--d-lg-inline-flex--2VP9Q";
export const dLgNone = "footer-module--d-lg-none--2S-Hz";
export const flexLgFill = "footer-module--flex-lg-fill--2QfyH";
export const flexLgRow = "footer-module--flex-lg-row--OHXWN";
export const flexLgColumn = "footer-module--flex-lg-column--2GaFF";
export const flexLgRowReverse = "footer-module--flex-lg-row-reverse--1B-Uh";
export const flexLgColumnReverse = "footer-module--flex-lg-column-reverse--AaBVI";
export const flexLgGrow0 = "footer-module--flex-lg-grow-0--2HvXr";
export const flexLgGrow1 = "footer-module--flex-lg-grow-1--6qHe2";
export const flexLgShrink0 = "footer-module--flex-lg-shrink-0--3RtDr";
export const flexLgShrink1 = "footer-module--flex-lg-shrink-1--2ON9E";
export const flexLgWrap = "footer-module--flex-lg-wrap--U3TYa";
export const flexLgNowrap = "footer-module--flex-lg-nowrap--2PAXF";
export const flexLgWrapReverse = "footer-module--flex-lg-wrap-reverse--2qo1V";
export const gapLg0 = "footer-module--gap-lg-0--3ziIk";
export const gapLg1 = "footer-module--gap-lg-1--3yUV_";
export const gapLg2 = "footer-module--gap-lg-2--25Vuv";
export const gapLg3 = "footer-module--gap-lg-3--wvRu_";
export const gapLg4 = "footer-module--gap-lg-4--2mKTw";
export const gapLg5 = "footer-module--gap-lg-5--VopP7";
export const justifyContentLgStart = "footer-module--justify-content-lg-start--8ZhLA";
export const justifyContentLgEnd = "footer-module--justify-content-lg-end--3rzG8";
export const justifyContentLgCenter = "footer-module--justify-content-lg-center--WEPVX";
export const justifyContentLgBetween = "footer-module--justify-content-lg-between--1wx7Z";
export const justifyContentLgAround = "footer-module--justify-content-lg-around--3oTIl";
export const justifyContentLgEvenly = "footer-module--justify-content-lg-evenly--3kE8y";
export const alignItemsLgStart = "footer-module--align-items-lg-start--360eB";
export const alignItemsLgEnd = "footer-module--align-items-lg-end--2I0Xe";
export const alignItemsLgCenter = "footer-module--align-items-lg-center--69sh9";
export const alignItemsLgBaseline = "footer-module--align-items-lg-baseline--eCzHh";
export const alignItemsLgStretch = "footer-module--align-items-lg-stretch--3Lsjp";
export const alignContentLgStart = "footer-module--align-content-lg-start--1c55T";
export const alignContentLgEnd = "footer-module--align-content-lg-end--mxXty";
export const alignContentLgCenter = "footer-module--align-content-lg-center--34MyD";
export const alignContentLgBetween = "footer-module--align-content-lg-between--1pdxl";
export const alignContentLgAround = "footer-module--align-content-lg-around--2xR5S";
export const alignContentLgStretch = "footer-module--align-content-lg-stretch--nWeZ_";
export const alignSelfLgAuto = "footer-module--align-self-lg-auto--2aqb0";
export const alignSelfLgStart = "footer-module--align-self-lg-start--2JAOo";
export const alignSelfLgEnd = "footer-module--align-self-lg-end--1pzBL";
export const alignSelfLgCenter = "footer-module--align-self-lg-center--1vSwY";
export const alignSelfLgBaseline = "footer-module--align-self-lg-baseline--1yrb1";
export const alignSelfLgStretch = "footer-module--align-self-lg-stretch--3y0xR";
export const orderLgFirst = "footer-module--order-lg-first--2hH7E";
export const orderLg0 = "footer-module--order-lg-0--38vJl";
export const orderLg1 = "footer-module--order-lg-1--38_zs";
export const orderLg2 = "footer-module--order-lg-2--1AWYb";
export const orderLg3 = "footer-module--order-lg-3--2WWto";
export const orderLg4 = "footer-module--order-lg-4--1AAkg";
export const orderLg5 = "footer-module--order-lg-5--19jA-";
export const orderLgLast = "footer-module--order-lg-last--4LOdP";
export const mLg0 = "footer-module--m-lg-0--23Efh";
export const mLg1 = "footer-module--m-lg-1--2D9rM";
export const mLg2 = "footer-module--m-lg-2--17Ztp";
export const mLg3 = "footer-module--m-lg-3--3Z_h8";
export const mLg4 = "footer-module--m-lg-4--HQkSV";
export const mLg5 = "footer-module--m-lg-5--ZnGse";
export const mLgAuto = "footer-module--m-lg-auto--153Vd";
export const mxLg0 = "footer-module--mx-lg-0--1Qej6";
export const mxLg1 = "footer-module--mx-lg-1--2RqPu";
export const mxLg2 = "footer-module--mx-lg-2--3-E3n";
export const mxLg3 = "footer-module--mx-lg-3--3smvd";
export const mxLg4 = "footer-module--mx-lg-4--2u0Y9";
export const mxLg5 = "footer-module--mx-lg-5--1Ot0q";
export const mxLgAuto = "footer-module--mx-lg-auto--1tlP5";
export const myLg0 = "footer-module--my-lg-0--Onfgd";
export const myLg1 = "footer-module--my-lg-1--2bb-q";
export const myLg2 = "footer-module--my-lg-2--1NDxa";
export const myLg3 = "footer-module--my-lg-3--rP0fE";
export const myLg4 = "footer-module--my-lg-4--11UfF";
export const myLg5 = "footer-module--my-lg-5--1H82x";
export const myLgAuto = "footer-module--my-lg-auto--aRzAO";
export const mtLg0 = "footer-module--mt-lg-0--26S_Z";
export const mtLg1 = "footer-module--mt-lg-1--3jah8";
export const mtLg2 = "footer-module--mt-lg-2--1pttl";
export const mtLg3 = "footer-module--mt-lg-3--3HnE0";
export const mtLg4 = "footer-module--mt-lg-4--1-4KM";
export const mtLg5 = "footer-module--mt-lg-5--wl2ot";
export const mtLgAuto = "footer-module--mt-lg-auto--3anwc";
export const meLg0 = "footer-module--me-lg-0--2Dcuz";
export const meLg1 = "footer-module--me-lg-1--28pP0";
export const meLg2 = "footer-module--me-lg-2--25Bhp";
export const meLg3 = "footer-module--me-lg-3--2ZvES";
export const meLg4 = "footer-module--me-lg-4--SvS-b";
export const meLg5 = "footer-module--me-lg-5--1dC_E";
export const meLgAuto = "footer-module--me-lg-auto--1i8Lx";
export const mbLg0 = "footer-module--mb-lg-0--3PKyI";
export const mbLg1 = "footer-module--mb-lg-1--GxPPB";
export const mbLg2 = "footer-module--mb-lg-2--1JgNJ";
export const mbLg3 = "footer-module--mb-lg-3--2qS7M";
export const mbLg4 = "footer-module--mb-lg-4--3FGd0";
export const mbLg5 = "footer-module--mb-lg-5--1MGqU";
export const mbLgAuto = "footer-module--mb-lg-auto--1gveD";
export const msLg0 = "footer-module--ms-lg-0--2EoZq";
export const msLg1 = "footer-module--ms-lg-1--2KjiJ";
export const msLg2 = "footer-module--ms-lg-2--xA2Bi";
export const msLg3 = "footer-module--ms-lg-3--259Gj";
export const msLg4 = "footer-module--ms-lg-4--4oXS_";
export const msLg5 = "footer-module--ms-lg-5--23ULC";
export const msLgAuto = "footer-module--ms-lg-auto--1N1v6";
export const pLg0 = "footer-module--p-lg-0--2ooTZ";
export const pLg1 = "footer-module--p-lg-1--28BSe";
export const pLg2 = "footer-module--p-lg-2--3qz7d";
export const pLg3 = "footer-module--p-lg-3--3PfTz";
export const pLg4 = "footer-module--p-lg-4--39MgR";
export const pLg5 = "footer-module--p-lg-5--2VgOV";
export const pxLg0 = "footer-module--px-lg-0--2uc8-";
export const pxLg1 = "footer-module--px-lg-1--3Udg4";
export const pxLg2 = "footer-module--px-lg-2--3dKbf";
export const pxLg3 = "footer-module--px-lg-3---SJR_";
export const pxLg4 = "footer-module--px-lg-4--2VoDH";
export const pxLg5 = "footer-module--px-lg-5--3LRN1";
export const pyLg0 = "footer-module--py-lg-0--nZgOP";
export const pyLg1 = "footer-module--py-lg-1--1q3Vk";
export const pyLg2 = "footer-module--py-lg-2--3WfrU";
export const pyLg3 = "footer-module--py-lg-3--2Q4uY";
export const pyLg4 = "footer-module--py-lg-4--2JUnj";
export const pyLg5 = "footer-module--py-lg-5--1z5cp";
export const ptLg0 = "footer-module--pt-lg-0--1UZE5";
export const ptLg1 = "footer-module--pt-lg-1--3tAnc";
export const ptLg2 = "footer-module--pt-lg-2--3IEoI";
export const ptLg3 = "footer-module--pt-lg-3--oxsYM";
export const ptLg4 = "footer-module--pt-lg-4--6B4eY";
export const ptLg5 = "footer-module--pt-lg-5--1OHr3";
export const peLg0 = "footer-module--pe-lg-0--2ezjb";
export const peLg1 = "footer-module--pe-lg-1--33FZt";
export const peLg2 = "footer-module--pe-lg-2--3hEVp";
export const peLg3 = "footer-module--pe-lg-3--1mWGk";
export const peLg4 = "footer-module--pe-lg-4--V9gZd";
export const peLg5 = "footer-module--pe-lg-5--2pBcz";
export const pbLg0 = "footer-module--pb-lg-0--37Lj2";
export const pbLg1 = "footer-module--pb-lg-1--1Ma3z";
export const pbLg2 = "footer-module--pb-lg-2--2r4yu";
export const pbLg3 = "footer-module--pb-lg-3--F1b8d";
export const pbLg4 = "footer-module--pb-lg-4--3gVqu";
export const pbLg5 = "footer-module--pb-lg-5--1sCgB";
export const psLg0 = "footer-module--ps-lg-0--17f0-";
export const psLg1 = "footer-module--ps-lg-1--1oUo2";
export const psLg2 = "footer-module--ps-lg-2--g-2H9";
export const psLg3 = "footer-module--ps-lg-3--2pgzS";
export const psLg4 = "footer-module--ps-lg-4--35NzW";
export const psLg5 = "footer-module--ps-lg-5--3SxJn";
export const textLgStart = "footer-module--text-lg-start--3aaSn";
export const textLgEnd = "footer-module--text-lg-end--3XsrK";
export const textLgCenter = "footer-module--text-lg-center--8Ydea";
export const floatXlStart = "footer-module--float-xl-start--2cbRq";
export const floatXlEnd = "footer-module--float-xl-end--2q832";
export const floatXlNone = "footer-module--float-xl-none--2PWUr";
export const dXlInline = "footer-module--d-xl-inline--6Lrre";
export const dXlInlineBlock = "footer-module--d-xl-inline-block--3PRSo";
export const dXlBlock = "footer-module--d-xl-block--1k_CD";
export const dXlGrid = "footer-module--d-xl-grid--LYpei";
export const dXlTable = "footer-module--d-xl-table--nXJOZ";
export const dXlTableRow = "footer-module--d-xl-table-row--ZtM4T";
export const dXlTableCell = "footer-module--d-xl-table-cell--1RDlB";
export const dXlFlex = "footer-module--d-xl-flex--QaHlp";
export const dXlInlineFlex = "footer-module--d-xl-inline-flex--J6YSq";
export const dXlNone = "footer-module--d-xl-none--d-1eR";
export const flexXlFill = "footer-module--flex-xl-fill--TsX95";
export const flexXlRow = "footer-module--flex-xl-row--1dsB3";
export const flexXlColumn = "footer-module--flex-xl-column--1lWw2";
export const flexXlRowReverse = "footer-module--flex-xl-row-reverse--Tc8CQ";
export const flexXlColumnReverse = "footer-module--flex-xl-column-reverse--mgW6P";
export const flexXlGrow0 = "footer-module--flex-xl-grow-0--2Sit2";
export const flexXlGrow1 = "footer-module--flex-xl-grow-1--3jld5";
export const flexXlShrink0 = "footer-module--flex-xl-shrink-0--Zb6I3";
export const flexXlShrink1 = "footer-module--flex-xl-shrink-1--37UnP";
export const flexXlWrap = "footer-module--flex-xl-wrap--2AY29";
export const flexXlNowrap = "footer-module--flex-xl-nowrap--3nGyS";
export const flexXlWrapReverse = "footer-module--flex-xl-wrap-reverse--1ZdPH";
export const gapXl0 = "footer-module--gap-xl-0--1-6r7";
export const gapXl1 = "footer-module--gap-xl-1--3QtY1";
export const gapXl2 = "footer-module--gap-xl-2--3EIF1";
export const gapXl3 = "footer-module--gap-xl-3--1SwjZ";
export const gapXl4 = "footer-module--gap-xl-4--nh0nq";
export const gapXl5 = "footer-module--gap-xl-5--1Y7z4";
export const justifyContentXlStart = "footer-module--justify-content-xl-start--3X55n";
export const justifyContentXlEnd = "footer-module--justify-content-xl-end--2j-7C";
export const justifyContentXlCenter = "footer-module--justify-content-xl-center--3BhcQ";
export const justifyContentXlBetween = "footer-module--justify-content-xl-between--2Lhlj";
export const justifyContentXlAround = "footer-module--justify-content-xl-around--2Rae9";
export const justifyContentXlEvenly = "footer-module--justify-content-xl-evenly--2KQ7G";
export const alignItemsXlStart = "footer-module--align-items-xl-start--9Xt_V";
export const alignItemsXlEnd = "footer-module--align-items-xl-end--3WYE8";
export const alignItemsXlCenter = "footer-module--align-items-xl-center--2spWb";
export const alignItemsXlBaseline = "footer-module--align-items-xl-baseline--1bkrA";
export const alignItemsXlStretch = "footer-module--align-items-xl-stretch--21GgD";
export const alignContentXlStart = "footer-module--align-content-xl-start--2aQTg";
export const alignContentXlEnd = "footer-module--align-content-xl-end--ShKxV";
export const alignContentXlCenter = "footer-module--align-content-xl-center--39EwX";
export const alignContentXlBetween = "footer-module--align-content-xl-between--P6yR4";
export const alignContentXlAround = "footer-module--align-content-xl-around--pP8Bg";
export const alignContentXlStretch = "footer-module--align-content-xl-stretch--2Dx9x";
export const alignSelfXlAuto = "footer-module--align-self-xl-auto--3i00P";
export const alignSelfXlStart = "footer-module--align-self-xl-start--3cegO";
export const alignSelfXlEnd = "footer-module--align-self-xl-end--2yyh0";
export const alignSelfXlCenter = "footer-module--align-self-xl-center--3B90I";
export const alignSelfXlBaseline = "footer-module--align-self-xl-baseline--3t71w";
export const alignSelfXlStretch = "footer-module--align-self-xl-stretch--uLJDc";
export const orderXlFirst = "footer-module--order-xl-first--1h2Yt";
export const orderXl0 = "footer-module--order-xl-0--62P8a";
export const orderXl1 = "footer-module--order-xl-1--2t6__";
export const orderXl2 = "footer-module--order-xl-2--2zPHV";
export const orderXl3 = "footer-module--order-xl-3--1W43o";
export const orderXl4 = "footer-module--order-xl-4--3HCf7";
export const orderXl5 = "footer-module--order-xl-5--gYRAm";
export const orderXlLast = "footer-module--order-xl-last--2a_QT";
export const mXl0 = "footer-module--m-xl-0--1E_rq";
export const mXl1 = "footer-module--m-xl-1--1t4EZ";
export const mXl2 = "footer-module--m-xl-2--d61lK";
export const mXl3 = "footer-module--m-xl-3--1AxFm";
export const mXl4 = "footer-module--m-xl-4--39-LB";
export const mXl5 = "footer-module--m-xl-5--rk2U1";
export const mXlAuto = "footer-module--m-xl-auto--15uai";
export const mxXl0 = "footer-module--mx-xl-0--23Naa";
export const mxXl1 = "footer-module--mx-xl-1--1S6yw";
export const mxXl2 = "footer-module--mx-xl-2--e6Q7_";
export const mxXl3 = "footer-module--mx-xl-3--3_JJ6";
export const mxXl4 = "footer-module--mx-xl-4--1JD3M";
export const mxXl5 = "footer-module--mx-xl-5--3lq1l";
export const mxXlAuto = "footer-module--mx-xl-auto--2Zg8m";
export const myXl0 = "footer-module--my-xl-0--3uPvA";
export const myXl1 = "footer-module--my-xl-1--EulsG";
export const myXl2 = "footer-module--my-xl-2--319Kh";
export const myXl3 = "footer-module--my-xl-3--1LeX6";
export const myXl4 = "footer-module--my-xl-4--m2xcu";
export const myXl5 = "footer-module--my-xl-5--3PBxB";
export const myXlAuto = "footer-module--my-xl-auto--3Uhzi";
export const mtXl0 = "footer-module--mt-xl-0--2x47R";
export const mtXl1 = "footer-module--mt-xl-1--1s9eH";
export const mtXl2 = "footer-module--mt-xl-2--20L2Q";
export const mtXl3 = "footer-module--mt-xl-3--38SZy";
export const mtXl4 = "footer-module--mt-xl-4--GMBdP";
export const mtXl5 = "footer-module--mt-xl-5--2yc-z";
export const mtXlAuto = "footer-module--mt-xl-auto--KsAlH";
export const meXl0 = "footer-module--me-xl-0--1E2z7";
export const meXl1 = "footer-module--me-xl-1--j5YON";
export const meXl2 = "footer-module--me-xl-2--3xkMp";
export const meXl3 = "footer-module--me-xl-3--1NHqo";
export const meXl4 = "footer-module--me-xl-4--oTHGl";
export const meXl5 = "footer-module--me-xl-5--3RKXv";
export const meXlAuto = "footer-module--me-xl-auto--1EYCr";
export const mbXl0 = "footer-module--mb-xl-0--XF69b";
export const mbXl1 = "footer-module--mb-xl-1--3sgGU";
export const mbXl2 = "footer-module--mb-xl-2--25mlC";
export const mbXl3 = "footer-module--mb-xl-3--3msVr";
export const mbXl4 = "footer-module--mb-xl-4--1I6Jl";
export const mbXl5 = "footer-module--mb-xl-5--2jeBS";
export const mbXlAuto = "footer-module--mb-xl-auto--1Ajq8";
export const msXl0 = "footer-module--ms-xl-0--FHfm0";
export const msXl1 = "footer-module--ms-xl-1--2Hlek";
export const msXl2 = "footer-module--ms-xl-2--C27Wg";
export const msXl3 = "footer-module--ms-xl-3--3K3zE";
export const msXl4 = "footer-module--ms-xl-4--WNQnZ";
export const msXl5 = "footer-module--ms-xl-5--2utkD";
export const msXlAuto = "footer-module--ms-xl-auto--CliQX";
export const pXl0 = "footer-module--p-xl-0--3DJh1";
export const pXl1 = "footer-module--p-xl-1--yYOw8";
export const pXl2 = "footer-module--p-xl-2--2kice";
export const pXl3 = "footer-module--p-xl-3--23yny";
export const pXl4 = "footer-module--p-xl-4--2h7Vw";
export const pXl5 = "footer-module--p-xl-5--3K2tG";
export const pxXl0 = "footer-module--px-xl-0--2zFXr";
export const pxXl1 = "footer-module--px-xl-1--2C2oG";
export const pxXl2 = "footer-module--px-xl-2--uoJ6r";
export const pxXl3 = "footer-module--px-xl-3--1eAMi";
export const pxXl4 = "footer-module--px-xl-4--3I2sk";
export const pxXl5 = "footer-module--px-xl-5--3eHJV";
export const pyXl0 = "footer-module--py-xl-0--OmTc8";
export const pyXl1 = "footer-module--py-xl-1--2xMH9";
export const pyXl2 = "footer-module--py-xl-2--3wUYo";
export const pyXl3 = "footer-module--py-xl-3--10zDX";
export const pyXl4 = "footer-module--py-xl-4--3-s54";
export const pyXl5 = "footer-module--py-xl-5--28yLn";
export const ptXl0 = "footer-module--pt-xl-0--3Qb9d";
export const ptXl1 = "footer-module--pt-xl-1--xvysS";
export const ptXl2 = "footer-module--pt-xl-2--2UNZg";
export const ptXl3 = "footer-module--pt-xl-3--1RBKo";
export const ptXl4 = "footer-module--pt-xl-4--EXPE0";
export const ptXl5 = "footer-module--pt-xl-5--1gNaU";
export const peXl0 = "footer-module--pe-xl-0--2nw3k";
export const peXl1 = "footer-module--pe-xl-1--2F5ws";
export const peXl2 = "footer-module--pe-xl-2--252eh";
export const peXl3 = "footer-module--pe-xl-3--35Uar";
export const peXl4 = "footer-module--pe-xl-4--1eJOh";
export const peXl5 = "footer-module--pe-xl-5--3I4GC";
export const pbXl0 = "footer-module--pb-xl-0--1yVkX";
export const pbXl1 = "footer-module--pb-xl-1--1PaV9";
export const pbXl2 = "footer-module--pb-xl-2--gYf8x";
export const pbXl3 = "footer-module--pb-xl-3--3obco";
export const pbXl4 = "footer-module--pb-xl-4--2VeaA";
export const pbXl5 = "footer-module--pb-xl-5--1mn-A";
export const psXl0 = "footer-module--ps-xl-0--2qZSQ";
export const psXl1 = "footer-module--ps-xl-1--VwWGA";
export const psXl2 = "footer-module--ps-xl-2--1lJk7";
export const psXl3 = "footer-module--ps-xl-3--3BuRA";
export const psXl4 = "footer-module--ps-xl-4--3CNUq";
export const psXl5 = "footer-module--ps-xl-5--2VDhI";
export const textXlStart = "footer-module--text-xl-start--2LwZ2";
export const textXlEnd = "footer-module--text-xl-end--9Kq0r";
export const textXlCenter = "footer-module--text-xl-center--16DLI";
export const floatXxlStart = "footer-module--float-xxl-start--2hrSr";
export const floatXxlEnd = "footer-module--float-xxl-end--3EoS4";
export const floatXxlNone = "footer-module--float-xxl-none--1gzaE";
export const dXxlInline = "footer-module--d-xxl-inline--20c2l";
export const dXxlInlineBlock = "footer-module--d-xxl-inline-block--1-l0L";
export const dXxlBlock = "footer-module--d-xxl-block--1PpAG";
export const dXxlGrid = "footer-module--d-xxl-grid--2Imfb";
export const dXxlTable = "footer-module--d-xxl-table--2e2NE";
export const dXxlTableRow = "footer-module--d-xxl-table-row--2MSax";
export const dXxlTableCell = "footer-module--d-xxl-table-cell--1NeJ5";
export const dXxlFlex = "footer-module--d-xxl-flex--9ZPHA";
export const dXxlInlineFlex = "footer-module--d-xxl-inline-flex--3crZy";
export const dXxlNone = "footer-module--d-xxl-none--1FPMf";
export const flexXxlFill = "footer-module--flex-xxl-fill--4lQ1h";
export const flexXxlRow = "footer-module--flex-xxl-row--2a-ru";
export const flexXxlColumn = "footer-module--flex-xxl-column--xL3so";
export const flexXxlRowReverse = "footer-module--flex-xxl-row-reverse--1ByEh";
export const flexXxlColumnReverse = "footer-module--flex-xxl-column-reverse--f2C-g";
export const flexXxlGrow0 = "footer-module--flex-xxl-grow-0--2wvhm";
export const flexXxlGrow1 = "footer-module--flex-xxl-grow-1--kQi4f";
export const flexXxlShrink0 = "footer-module--flex-xxl-shrink-0--28SF6";
export const flexXxlShrink1 = "footer-module--flex-xxl-shrink-1--JrOYw";
export const flexXxlWrap = "footer-module--flex-xxl-wrap--3-8Oy";
export const flexXxlNowrap = "footer-module--flex-xxl-nowrap--2qL0G";
export const flexXxlWrapReverse = "footer-module--flex-xxl-wrap-reverse--10sfP";
export const gapXxl0 = "footer-module--gap-xxl-0--xzj20";
export const gapXxl1 = "footer-module--gap-xxl-1--RNWbb";
export const gapXxl2 = "footer-module--gap-xxl-2--1BRdK";
export const gapXxl3 = "footer-module--gap-xxl-3--pBIYQ";
export const gapXxl4 = "footer-module--gap-xxl-4--2bzbF";
export const gapXxl5 = "footer-module--gap-xxl-5--3Zz_V";
export const justifyContentXxlStart = "footer-module--justify-content-xxl-start--EX4mS";
export const justifyContentXxlEnd = "footer-module--justify-content-xxl-end--1Epdy";
export const justifyContentXxlCenter = "footer-module--justify-content-xxl-center--GJucL";
export const justifyContentXxlBetween = "footer-module--justify-content-xxl-between--Ykdku";
export const justifyContentXxlAround = "footer-module--justify-content-xxl-around--2s_Na";
export const justifyContentXxlEvenly = "footer-module--justify-content-xxl-evenly--BpVhE";
export const alignItemsXxlStart = "footer-module--align-items-xxl-start--3cblp";
export const alignItemsXxlEnd = "footer-module--align-items-xxl-end--1p9TR";
export const alignItemsXxlCenter = "footer-module--align-items-xxl-center--N2n-b";
export const alignItemsXxlBaseline = "footer-module--align-items-xxl-baseline--2JOkk";
export const alignItemsXxlStretch = "footer-module--align-items-xxl-stretch--bpxqj";
export const alignContentXxlStart = "footer-module--align-content-xxl-start--377Jm";
export const alignContentXxlEnd = "footer-module--align-content-xxl-end--23P-Y";
export const alignContentXxlCenter = "footer-module--align-content-xxl-center--3gYxQ";
export const alignContentXxlBetween = "footer-module--align-content-xxl-between--30CoR";
export const alignContentXxlAround = "footer-module--align-content-xxl-around--122uv";
export const alignContentXxlStretch = "footer-module--align-content-xxl-stretch--1B8J2";
export const alignSelfXxlAuto = "footer-module--align-self-xxl-auto--3HfB_";
export const alignSelfXxlStart = "footer-module--align-self-xxl-start--WG1g_";
export const alignSelfXxlEnd = "footer-module--align-self-xxl-end--3fy8M";
export const alignSelfXxlCenter = "footer-module--align-self-xxl-center--mAco8";
export const alignSelfXxlBaseline = "footer-module--align-self-xxl-baseline--2hY4R";
export const alignSelfXxlStretch = "footer-module--align-self-xxl-stretch--2yUpF";
export const orderXxlFirst = "footer-module--order-xxl-first--2vPh6";
export const orderXxl0 = "footer-module--order-xxl-0--fHoMB";
export const orderXxl1 = "footer-module--order-xxl-1--2wlqG";
export const orderXxl2 = "footer-module--order-xxl-2--3wZGA";
export const orderXxl3 = "footer-module--order-xxl-3--2z6Qo";
export const orderXxl4 = "footer-module--order-xxl-4--Qp8Ps";
export const orderXxl5 = "footer-module--order-xxl-5--1a1jU";
export const orderXxlLast = "footer-module--order-xxl-last--1c87K";
export const mXxl0 = "footer-module--m-xxl-0--1ivoJ";
export const mXxl1 = "footer-module--m-xxl-1--37cCF";
export const mXxl2 = "footer-module--m-xxl-2--2dn9j";
export const mXxl3 = "footer-module--m-xxl-3--3lg_X";
export const mXxl4 = "footer-module--m-xxl-4--3-2wD";
export const mXxl5 = "footer-module--m-xxl-5--imyWq";
export const mXxlAuto = "footer-module--m-xxl-auto--2oEog";
export const mxXxl0 = "footer-module--mx-xxl-0--36i7r";
export const mxXxl1 = "footer-module--mx-xxl-1--1YXcV";
export const mxXxl2 = "footer-module--mx-xxl-2--1jgir";
export const mxXxl3 = "footer-module--mx-xxl-3--Ks_6g";
export const mxXxl4 = "footer-module--mx-xxl-4--2PiP6";
export const mxXxl5 = "footer-module--mx-xxl-5--2_4bm";
export const mxXxlAuto = "footer-module--mx-xxl-auto---Td2t";
export const myXxl0 = "footer-module--my-xxl-0--1KwOb";
export const myXxl1 = "footer-module--my-xxl-1--1wNFW";
export const myXxl2 = "footer-module--my-xxl-2--1dTG7";
export const myXxl3 = "footer-module--my-xxl-3--BSxEy";
export const myXxl4 = "footer-module--my-xxl-4--1R6gB";
export const myXxl5 = "footer-module--my-xxl-5--26-in";
export const myXxlAuto = "footer-module--my-xxl-auto--OHPTQ";
export const mtXxl0 = "footer-module--mt-xxl-0--1V8zt";
export const mtXxl1 = "footer-module--mt-xxl-1--1MlCN";
export const mtXxl2 = "footer-module--mt-xxl-2--2pJ1X";
export const mtXxl3 = "footer-module--mt-xxl-3--19JfC";
export const mtXxl4 = "footer-module--mt-xxl-4--2XsyK";
export const mtXxl5 = "footer-module--mt-xxl-5--1NwjB";
export const mtXxlAuto = "footer-module--mt-xxl-auto--1qYsm";
export const meXxl0 = "footer-module--me-xxl-0--3TlIR";
export const meXxl1 = "footer-module--me-xxl-1--2Qy3G";
export const meXxl2 = "footer-module--me-xxl-2--3Mwwy";
export const meXxl3 = "footer-module--me-xxl-3--2n3dQ";
export const meXxl4 = "footer-module--me-xxl-4--2GVoX";
export const meXxl5 = "footer-module--me-xxl-5--3309P";
export const meXxlAuto = "footer-module--me-xxl-auto--1MEnG";
export const mbXxl0 = "footer-module--mb-xxl-0--ZN9HQ";
export const mbXxl1 = "footer-module--mb-xxl-1--28HQZ";
export const mbXxl2 = "footer-module--mb-xxl-2--3YfLO";
export const mbXxl3 = "footer-module--mb-xxl-3--37m2F";
export const mbXxl4 = "footer-module--mb-xxl-4--13zz_";
export const mbXxl5 = "footer-module--mb-xxl-5--3Dz10";
export const mbXxlAuto = "footer-module--mb-xxl-auto--3H_E5";
export const msXxl0 = "footer-module--ms-xxl-0--33bX_";
export const msXxl1 = "footer-module--ms-xxl-1--uCWqa";
export const msXxl2 = "footer-module--ms-xxl-2--3ayxZ";
export const msXxl3 = "footer-module--ms-xxl-3--2Nl_q";
export const msXxl4 = "footer-module--ms-xxl-4--2lvub";
export const msXxl5 = "footer-module--ms-xxl-5--1dlnO";
export const msXxlAuto = "footer-module--ms-xxl-auto--3PpWr";
export const pXxl0 = "footer-module--p-xxl-0--1Qch9";
export const pXxl1 = "footer-module--p-xxl-1--3ODDy";
export const pXxl2 = "footer-module--p-xxl-2--14sMQ";
export const pXxl3 = "footer-module--p-xxl-3--1mQT9";
export const pXxl4 = "footer-module--p-xxl-4--3MDdK";
export const pXxl5 = "footer-module--p-xxl-5--1rX5_";
export const pxXxl0 = "footer-module--px-xxl-0--ypNPa";
export const pxXxl1 = "footer-module--px-xxl-1--16Hqx";
export const pxXxl2 = "footer-module--px-xxl-2--17Hs0";
export const pxXxl3 = "footer-module--px-xxl-3--1rjB9";
export const pxXxl4 = "footer-module--px-xxl-4--2TmSk";
export const pxXxl5 = "footer-module--px-xxl-5--3IALF";
export const pyXxl0 = "footer-module--py-xxl-0--3Q-im";
export const pyXxl1 = "footer-module--py-xxl-1--_ZNQy";
export const pyXxl2 = "footer-module--py-xxl-2--1CF6S";
export const pyXxl3 = "footer-module--py-xxl-3--2xo40";
export const pyXxl4 = "footer-module--py-xxl-4--2E9dT";
export const pyXxl5 = "footer-module--py-xxl-5--2RHtm";
export const ptXxl0 = "footer-module--pt-xxl-0--2QsdC";
export const ptXxl1 = "footer-module--pt-xxl-1--xHu7k";
export const ptXxl2 = "footer-module--pt-xxl-2--T9-zx";
export const ptXxl3 = "footer-module--pt-xxl-3--1W--7";
export const ptXxl4 = "footer-module--pt-xxl-4--2mv0m";
export const ptXxl5 = "footer-module--pt-xxl-5--1Goz0";
export const peXxl0 = "footer-module--pe-xxl-0--bo1FZ";
export const peXxl1 = "footer-module--pe-xxl-1--3ZPJj";
export const peXxl2 = "footer-module--pe-xxl-2--1SDjl";
export const peXxl3 = "footer-module--pe-xxl-3--2o0N6";
export const peXxl4 = "footer-module--pe-xxl-4--3Nyc8";
export const peXxl5 = "footer-module--pe-xxl-5--1bXXF";
export const pbXxl0 = "footer-module--pb-xxl-0--1gbXj";
export const pbXxl1 = "footer-module--pb-xxl-1--2f_Cl";
export const pbXxl2 = "footer-module--pb-xxl-2--3AwkN";
export const pbXxl3 = "footer-module--pb-xxl-3--3RKaU";
export const pbXxl4 = "footer-module--pb-xxl-4--1h1cJ";
export const pbXxl5 = "footer-module--pb-xxl-5--CXrNp";
export const psXxl0 = "footer-module--ps-xxl-0--3Xr2c";
export const psXxl1 = "footer-module--ps-xxl-1--3-RNP";
export const psXxl2 = "footer-module--ps-xxl-2--1qCX3";
export const psXxl3 = "footer-module--ps-xxl-3--xI7ud";
export const psXxl4 = "footer-module--ps-xxl-4--3HW0B";
export const psXxl5 = "footer-module--ps-xxl-5--1-oPL";
export const textXxlStart = "footer-module--text-xxl-start--2xnow";
export const textXxlEnd = "footer-module--text-xxl-end--11Y53";
export const textXxlCenter = "footer-module--text-xxl-center--qVDzp";
export const fsSm1 = "footer-module--fs-sm-1--3wGrE";
export const fsSm2 = "footer-module--fs-sm-2--2kO5j";
export const fsSm3 = "footer-module--fs-sm-3--3SBPS";
export const fsSm4 = "footer-module--fs-sm-4--3Rxe3";
export const fsMd1 = "footer-module--fs-md-1--42fnJ";
export const fsMd2 = "footer-module--fs-md-2--VuJVb";
export const fsMd3 = "footer-module--fs-md-3--1R3DN";
export const fsMd4 = "footer-module--fs-md-4--2GR3a";
export const fsLg1 = "footer-module--fs-lg-1--1Uo8K";
export const fsLg2 = "footer-module--fs-lg-2--3uJwY";
export const fsLg3 = "footer-module--fs-lg-3--3SIVp";
export const fsLg4 = "footer-module--fs-lg-4--2-cXk";
export const dPrintInline = "footer-module--d-print-inline--2Vzcz";
export const dPrintInlineBlock = "footer-module--d-print-inline-block--_0bcU";
export const dPrintBlock = "footer-module--d-print-block--gB9Vr";
export const dPrintGrid = "footer-module--d-print-grid--3vlhZ";
export const dPrintTable = "footer-module--d-print-table--1rz31";
export const dPrintTableRow = "footer-module--d-print-table-row--3fIKN";
export const dPrintTableCell = "footer-module--d-print-table-cell--_BFrj";
export const dPrintFlex = "footer-module--d-print-flex--31DUA";
export const dPrintInlineFlex = "footer-module--d-print-inline-flex--35LhT";
export const dPrintNone = "footer-module--d-print-none--avfOK";
export const footer = "footer-module--footer--2Wk7H";
export const contact = "footer-module--contact--1if1J";