import React, { useEffect, useRef, useState } from 'react';
import { Link } from "gatsby";
import * as styles from './mobile-overlay.module.scss';
import { useMenuContext } from "../../provider/menuProvider";

import TimesSVG from "../../icons/fontawesome/light/times.svg";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";

import ChevronRightSVG from "../../icons/fontawesome/light/chevron-right.svg";
import ChevronDownSVG from "../../icons/fontawesome/light/chevron-down.svg";

const MobileOverlay = ( { data } ) => {
    const menuContext = useMenuContext();
    const ref = useRef();
    const [ selectedCategory, setSelectedCategory ] = useState(null);

    useEffect(
        () => {
            if (menuContext.showMobileOverlay) {

                if (ref.current) {
                    disableBodyScroll(ref.current);
                }

            } else {
                clearAllBodyScrollLocks();
            }
        },
        [ menuContext.showMobileOverlay ]
    )

    if (!menuContext.showMobileOverlay) {
        return null;
    }

    return <div className={styles.overlay + " p-4"} ref={ref}>
        <h2>Navigation</h2>
        <TimesSVG className={"svgFill " + styles.close}
                  onClick={ () => { menuContext.setShowMobileOverlay(s => false) } }
        />

        <ul className={styles.navigation}>
            {
                data.strapiMenu.elements.map(e => {

                    const strapiCategory = data.allStrapiCategories.nodes.find( n => n.strapiId === e.category.id );

                    return <>
                        <li className={"d-flex align-items-center"}
                            onClick={ () => setSelectedCategory(s => s === strapiCategory ? null : strapiCategory) }>
                            { selectedCategory === strapiCategory && <ChevronDownSVG className={"me-2 svgFill"} /> }
                            { selectedCategory !== strapiCategory && <ChevronRightSVG className={"me-2 svgFill"} /> }
                            { e.label }
                        </li>

                        {selectedCategory === strapiCategory &&
                        <ul className={styles.subNavigation}>
                            {
                                strapiCategory.subCategories.map(s => {
                                    return <Link to={"/kategorie/" + s.shopifyCollection?.handle}
                                                 onClick={ () => menuContext.setShowMobileOverlay(s => false) }>
                                        <li>
                                            {s.shopifyCollection?.title}
                                        </li>
                                    </Link>
                                })
                            }
                        </ul>
                        }

                    </>;
                })
            }
        </ul>

    </div>;

}

export default MobileOverlay;
