import React from 'react';

import GolfbagSVG from "../../icons/envato/golfbag.svg";
import TrolleySVG from "../../icons/envato/trolley.svg";
import TrainersSVG from "../../icons/envato/trainers.svg";
import WedgeSVG from "../../icons/envato/wedge.svg";
import GolfballLogoSVG from '../../icons/envato/golfball-logo.svg';
import GolfballSVG from '../../icons/envato/golfball.svg';

const HeaderNav = ( { node, category, className = '', onClick = () => { }  }) => {

    const label = node.label;

    const svgClass = "me-1";
    let svgElement = null;

    let additionalClass = "";

    switch (category.tag) {
        case "trolley":
            svgElement = <TrolleySVG className={svgClass} />;
            break;

        case "golfschläger":
            svgElement = <WedgeSVG className={svgClass} />;
            break;

        case "logo":
            svgElement = <GolfballLogoSVG className={svgClass} />;
            break;

        case "schuhe":
            svgElement = <TrainersSVG className={svgClass} />;
            break;

        case "bags":
            svgElement = <GolfbagSVG className={svgClass} />;
            break;

        case "balls":
            svgElement = <GolfballSVG className={svgClass} />
            break;

        case "sale":
            break;
    }

    return <li onClick={onClick} className={additionalClass + " " + className + " h-100 d-flex align-items-center"}>
        { svgElement }
        { label }
    </li>;

};

export default HeaderNav;
