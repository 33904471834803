import React from 'react';
import * as styles from './gsButton.module.scss';

import PropTypes from "prop-types";

const GsButton = ( {
                       className = '',
                       variant,
                       children,
                       disabled = false,
                       onClick = () => { }
                   } ) => {

    return <a className={styles.button + " " + styles[variant] + " " + className + " " + (disabled ? styles.disabled : "")}
              onClick={onClick}>
        { children }
    </a>

};

GsButton.propTypes = {
    variant: PropTypes.oneOf([ 'primary', 'accent' ]).isRequired
}

export default GsButton;
