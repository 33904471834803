// extracted by mini-css-extract-plugin
export const headerWrapper = "header-module--headerWrapper--1PVfS";
export const headerShadow = "header-module--headerShadow--2Mv3i";
export const subHeader = "header-module--subHeader--1s49J";
export const logo = "header-module--logo--b48UR";
export const nav = "header-module--nav--3_nQI";
export const shoppingBag = "header-module--shoppingBag--10Ec3";
export const cartAmount = "header-module--cartAmount--15kYq";
export const bars = "header-module--bars--H8hlq";
export const mobileWarenkorb = "header-module--mobileWarenkorb--3fL8r";
export const search = "header-module--search--3miky";