import React from 'react';
import * as styles from './footer.module.scss';
import { graphql, Link, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";

const Footer = ( { className = '' } ) => {

    const data = useStaticQuery(
        graphql`
            query footerContactQuery {
                strapiPageConfig {
                    kontakt
                  }
            }
        `
    )

    return <div className={className + " " + styles.footer}>

        <div className="container">

            <div className="row">
                <div className="col-12">
                    <div className={styles.contact}>
                        <h4>Kontakt</h4>
                        <ReactMarkdown allowDangerousHtml={true} children={data.strapiPageConfig.kontakt} />
                    </div>
                </div>
            </div>

            <div className="row mt-5">

                <div className="col-12 col-md-6 col-xl-3">
                    <h4>Mein Konto</h4>

                    <ul>
                        {/*<li>*/}
                        {/*    <Link to={"/konto"}>Mein Konto</Link>*/}
                        {/*</li>*/}

                        <li>
                            <Link to={"/warenkorb"}>Warenkorb</Link>
                        </li>

                        <li>
                            <Link to={"/konto/bestellungen"}>Meine Bestellungen</Link>
                        </li>
                    </ul>
                </div>

                <div className="col-12 col-md-6 col-xl-3 mt-5 mt-md-0">
                    <h4>Hilfe & Service</h4>

                    <ul>
                        <li>
                            <Link to={"/static/unternehmen"}>Unternehmen</Link>
                        </li>

                        <li>
                            <Link to={"/static/standorte"}>Standorte</Link>
                        </li>

                        <li>
                            <Link to={"/static/oeffnungszeiten"}>Öffnungszeiten</Link>
                        </li>

                        <li>
                            <Link to={"/static/zahlung-und-versand"}>Zahlung und Versand</Link>
                        </li>
                    </ul>
                </div>

                <div className="col-12 col-md-6 col-xl-3 mt-5 mt-md-0">
                    <h4>Rechte & Fristen</h4>

                    <ul>
                        <li>
                            <Link to={"/static/agb"}>Allgemeine Geschäftsbedigungen</Link>
                        </li>

                        <li>
                            <Link to={"/static/datenschutzerklaerung"}>Datenschutz (DSGVO)</Link>
                        </li>

                        <li>
                            <Link to={"/static/widerrufsrecht"}>Widerrufsrecht</Link>
                        </li>

                        <li>
                            <Link to={"/static/impressum"}>Impressum</Link>
                        </li>
                    </ul>
                </div>


            </div>
        </div>

    </div>

};

export default Footer;
