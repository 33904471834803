import React, { useContext, createContext, useState } from "react";

export const MenuContext = createContext();

export const MenuProvider = ( { children } ) => {

    const [ currentMegamenu, setCurrentMegamenu ] = useState(false);
    const [ showMobileOverlay, setShowMobileOverlay ] = useState(false);

    const menuValue = {
        currentMegamenu,
        setCurrentMegamenu,

        showMobileOverlay,
        setShowMobileOverlay
    };

    return (
        <MenuContext.Provider value={menuValue}>
            {children}
        </MenuContext.Provider>
    );

};

export const useMenuContext = () => {
    return useContext(MenuContext);
}
