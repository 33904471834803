import React from 'react';
import * as styles from './header-megamenu-fullcategory.module.scss';

import { Link } from "gatsby";
import GsButton from "../buttons/gsButton";

const HeaderMegaMenuFullCategory = (
    {
        children,
        className = '',
        shopifyCollection,
        onSelection = () => { }
    }
) => {

    const src = shopifyCollection.imageUrl;
    const handle = shopifyCollection.handle;

    return <div className={"d-flex align-items-center " + styles.fullCategoryWrapper}>
        <Link to={"/kategorie/" + handle} onClick={onSelection}>
            <img src={src} />
        </Link>

        <div className={"pe-4 " + styles.content}>
            { children }

            <Link to={"/kategorie/" + handle} onClick={onSelection}>
                <GsButton className={"d-inline-block mt-3"} variant={"primary"}>Jetzt konfigurieren</GsButton>
            </Link>
        </div>
    </div>

};

export default HeaderMegaMenuFullCategory;
