import React, { useEffect, useRef } from 'react';
import * as styles from './header-megamenu.module.scss';
import HeaderMegamenuCategory from "./header-megamenu-category";

import ChevronUpSVG from "../../icons/fontawesome/light/chevron-up.svg";
import CrossSVG from "../../icons/fontawesome/light/times.svg";
import { useMenuContext } from "../../provider/menuProvider";
import { disableBodyScroll } from "body-scroll-lock";
import HeaderMegaMenuFullCategory from "./header-megamenu-fullcategory";

const HeaderMegamenu = ( {
                             category,
                             onClose = () => { },
                             onSelection = () => { }
                         } ) => {

    const mainRef = useRef();
    const menuContext = useMenuContext();
    const cardCol = "col-3 my-3";

    useEffect(
        () => {
            if (!mainRef.current) {
                return;
            }

            disableBodyScroll(mainRef.current);
        },
        [ menuContext.currentMegamenu ]
    );

    return <>

        {menuContext.currentMegamenu === category.id &&

        <div className={styles.megamenu + " p-5"} ref={mainRef}>
            <div className="container">
                <div className="row">

                    <div className="col-12 pb-3 p-relative">

                        <CrossSVG onClick={onClose} className={styles.cross + " c-pointer svgFill"}/>

                        <h3>{category.title}</h3>

                        {
                            category.description &&
                                <div dangerouslySetInnerHTML={
                                        {
                                            __html: category.description
                                        }
                                    } />
                        }
                    </div>

                    <div className="col-12">

                        <div className="row">
                            {
                                category.subCategories.map(
                                    (sc) => {

                                        if (sc.shopifyCollection.title.toLowerCase().indexOf('logo golfbälle') !== -1) {

                                            return <div key={sc.shopifyCollection.id} className={"col-12"}>
                                                <HeaderMegaMenuFullCategory onSelection={onSelection} shopifyCollection={sc.shopifyCollection}>
                                                    <h3>Ihr Firmenlogo auf Ihren Golfball</h3>
                                                    <p>Laden Sie mit unserem Konfiguration Ihr gewünschtes Logo hoch und erhalten Sie Ihren individuell bedruckten Ball. Beweisen Sie ... Die ideale Werbebotschaft.</p>
                                                </HeaderMegaMenuFullCategory>
                                            </div>

                                        } else {
                                            return <div key={sc.shopifyCollection.id} className={cardCol}>
                                                <HeaderMegamenuCategory onSelection={onSelection} shopifyCollection={sc.shopifyCollection}/>
                                            </div>;
                                        }


                                    }
                                )
                            }
                        </div>

                    </div>

                    <div className="col-12 mt-4 text-center text-white">
                        <ChevronUpSVG onClick={onClose} className={styles.up + " svgFill c-pointer d-block mx-auto"}/>
                    </div>

                </div>
            </div>
        </div>

        }
    </>;

};

export default HeaderMegamenu;
