import React from 'react';
import * as styles from './layout.module.scss';

// import global scss
import '../../style/bootstrap/bootstrap-grid.scss';
import '../../style/bootstrap/bootstrap-reboot.scss';
import '../../style/common.scss';
import '../../style/custom-fonts.scss';

import "@fontsource/lato/100.css";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";

import Header from "./header";
import Footer from "./footer";
import { MenuProvider } from "../../provider/menuProvider";
import { StoreProvider } from "../../provider/storeProvider";
import { graphql, useStaticQuery } from "gatsby";
import MobileOverlay from "./mobile-overlay";

const Layout = ( { children } ) => {

    const data = useStaticQuery(
        graphql`
            query HeaderTeaserQuery {
                strapiPageConfig {
                    teaser
                }
                
                strapiMenu {
                    elements {
                      category {
                        id
                      }
                      id
                      label
                    }
                }
                  
                allStrapiCategories {
                    nodes {
                      subCategories {
                        id
                        shopifyCollection {
                          title
                          handle
                          imageUrl
                        }
                      }
                      title
                      id
                      tag
                      strapiId
                      description                 
                    }
                }
            }`
    );

    return <>

        <div className={styles.layout}>

            <StoreProvider>

                <MenuProvider>
                    <Header className={styles.header} data={data} />
                    <MobileOverlay data={data} />
                </MenuProvider>

                <div className={styles.content}>
                    { children }
                </div>

            </StoreProvider>

            <Footer className={styles.footer} />
        </div>
    </>;
};

export default Layout;
