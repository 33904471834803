import React from 'react';
import * as styles from './header-megamenu-category.module.scss';
import { Link } from "gatsby";

const HeaderMegamenuCategory = ( {
                                     className = '',
                                     shopifyCollection,
                                     onSelection = () => { }
                                 } ) => {

    const src = shopifyCollection.imageUrl;
    const title = shopifyCollection.title;
    const handle = shopifyCollection.handle;

    return <Link to={"/kategorie/" + handle} className={styles.categoryCardLink}
        onClick={onSelection} >

        <div className={styles.categoryCard + ' ' + className}>
            {src && <img src={src} className={styles.image}/> }
            <div className={styles.title}>{title}</div>
        </div>

    </Link>;


};

export default HeaderMegamenuCategory;
