import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import fetch from 'isomorphic-fetch'
import Client from 'shopify-buy'

export const StoreContext = createContext();

const client = Client.buildClient(
    {
        storefrontAccessToken: "10d2a086ddd3e10a3faf20eb62403492",
        domain: `golfsport-walter.myshopify.com`,
    },
    fetch
);

export const StoreProvider = ( { children } ) => {

    let initialStoreState = {
        client,
        adding: false,
        checkout: { lineItems: [] },
        products: [],
        shop: {},
    }

    const [ store, updateStore ] = useState(initialStoreState)
    const [ cartAmount, setCartAmount ] = useState(0);
    const isRemoved = useRef(false)

    useEffect(() => {
        const initializeCheckout = async () => {
            // Check for an existing cart.
            const isBrowser = typeof window !== 'undefined'
            const existingCheckoutID = isBrowser
                ? localStorage.getItem('shopify_checkout_id')
                : null

            const setCheckoutInState = checkout => {
                if (isBrowser) {
                    localStorage.setItem('shopify_checkout_id', checkout.id)
                }

                setCartAmount(checkout.lineItems?.length);

                updateStore(prevState => {
                    return { ...prevState, checkout }
                })
            }

            const createNewCheckout = () => store.client.checkout.create()
            const fetchCheckout = id => store.client.checkout.fetch(id)

            if (existingCheckoutID) {
                try {
                    const checkout = await fetchCheckout(existingCheckoutID)
                    // Make sure this cart hasn’t already been purchased.
                    if (!isRemoved.current && !checkout.completedAt) {
                        setCheckoutInState(checkout)
                        return
                    }
                } catch (e) {
                    localStorage.setItem('shopify_checkout_id', null)
                }
            }

            const newCheckout = await createNewCheckout();

            if (!isRemoved.current) {
                setCheckoutInState(newCheckout)
            }
        }

        initializeCheckout()
    }, [ store.client.checkout ])

    useEffect(() => () => {
        isRemoved.current = false
    });

    const provider = {
        store,
        addVariantToCart: async (variantId, quantity, customAttributes = undefined) => {

            console.log('*** add variant to cart');
            console.log(variantId, quantity, customAttributes);

            if (variantId === '' || !quantity) {
                console.error('Both a size and quantity are required.')
                return
            }

            updateStore(prevState => {
                return { ...prevState, adding: true }
            });

            setCartAmount(a => a + 1);

            const { checkout, client } = store

            const checkoutId = checkout.id
            const lineItemsToUpdate = [
                {
                    variantId,
                    quantity: parseInt(quantity, 10),
                    customAttributes
                },
            ]

            return client.checkout
                .addLineItems(checkoutId, lineItemsToUpdate)
                .then(checkout => {
                    updateStore(prevState => {
                        return { ...prevState, checkout, adding: false }
                    })
                })
        },
        removeLineItem: (lineItemID) => {
            let checkoutID = store.checkout?.id;

            setCartAmount( a => a - 1);

            return store.client.checkout
                .removeLineItems(checkoutID, [lineItemID])
                .then(res => {
                    updateStore(prevState => {
                        return { ...prevState, checkout: res }
                    })
                });
        },
        updateLineItem: async (lineItemID, quantity) => {
            let checkoutID = store.checkout?.id;

            const lineItemsToUpdate = [
                { id: lineItemID, quantity: parseInt(quantity, 10) },
            ]

            const res = await store.client.checkout.updateLineItems(checkoutID, lineItemsToUpdate);

            updateStore(prevState => {
                return { ...prevState, checkout: res }
            });
        },

        cartAmount
    };

    return <StoreContext.Provider value={provider}>
        {children}
    </StoreContext.Provider>;

};

export const useStoreContext = () => {
    return useContext(StoreContext);
}
